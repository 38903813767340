import React, {Component} from 'react'


import SearchMember from './Input/SearchMember'


class Members extends Component {                   // prikazuje sve aktivne clanove

    render(){
 
        return(


                <SearchMember/>

        );

    }



}


export default Members;

