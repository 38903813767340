import React, {Component} from 'react'
import Aux from '../hoc/Aux'
import {Route, Switch} from 'react-router-dom'

import Auth from '../containers/Auth/Auth'
import Nav from '../components/Nav/Nav'
import Main from './Main/Main'
import Members from '../components/Members/Members'
import Clubs from './Clubs/Clubs'
import RibolovClub from './Clubs/Club/ribolovClub'
import NogometClub from './Clubs/Club/nogometClub'
import BelaClub from './Clubs/Club/belaClub'
import PikadoClub from './Clubs/Club/pikadoClub'
import HumanitarianClub from './Clubs/Club/humanitarianClub'
import Passive from './Passive/Passive'
import GoverningBodies from './GoverningBodies/GoverningBodies'
import CentralCommittee from './GoverningBodies/Bodies/CentralCommittee'
import Presidency from './GoverningBodies/Bodies/Presidency'
import SupervisoryBoard from './GoverningBodies/Bodies/SupervisoryBoard'
import Logout from '../containers/Auth/Logout/Logout'
import Footer from '../components/Footer/Footer'

import '../assets/css/main.css'
import '../assets/css/nav.css'

import {connect} from 'react-redux'

class Layout extends Component {




    render() {
        return (
            <Aux>
                
                
                <section>
                
                    <div className="Auth">
                       <Route path="/" exact component={Auth} />
                    </div>     

                    <Route path="/(main|members|clubs|ribolov|nogomet|bela|pikado|humanitarniRad|passive|bodies|centralCommittee|presidency|supervisoryBoard)" component={this.props.isAuth ? Nav : null} />

                    <Route path="/main" exact component={this.props.isAuth ? Main : Auth} />  

                   <Route path="/members" exact component={this.props.isAuth ? Members : Auth} />  

                   <Route path="/clubs" exact component={this.props.isAuth ? Clubs : Auth} /> 

                   <Route path="/ribolov" exact component={this.props.isAuth ? RibolovClub : Auth} /> 

                   <Route path="/nogomet" exact component={this.props.isAuth ? NogometClub : Auth} /> 

                   <Route path="/bela" exact component={this.props.isAuth ? BelaClub : Auth} />

                   <Route path="/pikado" exact component={this.props.isAuth ? PikadoClub : Auth} />

                   <Route path="/humanitarniRad" exact component={this.props.isAuth ? HumanitarianClub : Auth} />

                   <Route path="/bodies" exact component={this.props.isAuth ? GoverningBodies : Auth} />

                   <Route path="/centralCommittee" exact component={this.props.isAuth ? CentralCommittee : Auth} />

                   <Route path="/presidency" exact component={this.props.isAuth ? Presidency : Auth} />

                   <Route path="/supervisoryBoard" exact component={this.props.isAuth ? SupervisoryBoard : Auth} />

                   <Route path="/passive" exact component={this.props.isAuth ? Passive : Auth} />

                   <Route path="/logout" exact component={Logout} /> 

                   <Route path="/(|main|members|clubs|ribolov|nogomet|bela|pikado|humanitarniRad|passive|bodies|centralCommittee|presidency|supervisoryBoard)" component={Footer} /> 
             
                </section>
            </Aux>
        );
    } 
    
                                            // Rute za odredene linkove ali prije toga provjerava postoji li token tj mora se prvo netko ulogirati sa emailom i lozinkom da moze dobiti pristup ostalim componentama 


}


const mapStateToProps = state => {         // to se povezuje sa initialState u reducers/auth.js
    return{
        isAuth: state.auth.token !== null // provjerava da ako token nije null tj postoji li token ili ne >>> ako postoji onda je true ako ne onda je null
    }
}


export default connect(mapStateToProps)(Layout);
