import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Aux from '../../hoc/Aux'
import Event from './Event'

import '../../assets/css/club.scss'

class Clubs extends Component {                     // prikazuje sve klubove i sve evente

    state={
        clubsItem:[
            {id: '1',imgUrl: 'http://desk.uhb-rh.com/assets/fish.png', title: 'ribolov'},
            {id: '2',imgUrl: 'http://desk.uhb-rh.com/assets/ball.png', title: 'nogomet'},
            {id: '3',imgUrl: 'http://desk.uhb-rh.com/assets/cards.png', title: 'bela'},
            {id: '4',imgUrl: 'http://desk.uhb-rh.com/assets/dart.png', title: 'pikado'},
            {id: '5',imgUrl: 'http://desk.uhb-rh.com/assets/humanitarian.svg', title: 'humanitarniRad'}
        ]
    }


    render() {

        let clubsItem = this.state.clubsItem.map(i => {
            
         return  <Link key={i.id} to={i.title}>
                <div className="ClubItem">
                    <img src={i.imgUrl} alt={i.title}/>
                </div>
                </Link>
        })

        return(

            <Aux>
                <div className="ClubItemWrap">
                    {clubsItem}
                </div>
                
                <Event/>
            </Aux>

        );

    }


}


export default Clubs;