import React from 'react'

const footer = (props) => (

    <footer>
       Copyright © Desk | Sva prava pridržana. <a href="https://inup.hr/">INUP</a>
    </footer>

);

export default footer;